module.exports={
  "name": "@uppy/transloadit",
  "description": "The Transloadit plugin can be used to upload files to Transloadit for all kinds of processing, such as transcoding video, resizing images, zipping/unzipping, and more",
  "version": "1.6.1",
  "license": "MIT",
  "main": "lib/index.js",
  "types": "types/index.d.ts",
  "keywords": [
    "file uploader",
    "transloadit",
    "file encoding",
    "encoding",
    "file processing",
    "video encoding",
    "crop",
    "resize",
    "watermark",
    "uppy",
    "uppy-plugin"
  ],
  "homepage": "https://uppy.io",
  "bugs": {
    "url": "https://github.com/transloadit/uppy/issues"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/transloadit/uppy.git"
  },
  "dependencies": {
    "@uppy/companion-client": "file:../companion-client",
    "@uppy/provider-views": "file:../provider-views",
    "@uppy/tus": "file:../tus",
    "@uppy/utils": "file:../utils",
    "component-emitter": "^1.2.1",
    "socket.io-client": "~2.2.0"
  },
  "peerDependencies": {
    "@uppy/core": "^1.0.0"
  }
}
